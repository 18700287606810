.item-card {
  border: none;
  width: fit-content;
  height: 100% !important;
  align-self: center;
  overflow: hidden;
  background: white;
}

.inside {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  /* background-color: #0070c0f3; */
  /* background-color: #0070c0b6; */
  background-color: var(--color-secondary);
  color: white;
  opacity: 0;
  transform: translateY(500px);
  transition: all 0.6s ease-in-out;
}
.inside.activehover {
  transform: translateY(0px);
  opacity: 1;
}
.cardimage {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
.cardimage:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.5s;
}


.button {
  border: 1px solid #0070c0 !important;
  background-position: 100%;
  color: #0070c0;
  transition: all ease-in 0.2s;
}
.button.hideBtn {
  visibility: hidden;
}
.button2 {
  background-color: #fff !important;
}

.button:hover {
  color: white !important;

  background-image: -webkit-linear-gradient(
    30deg,
    #2852db 50%,
    transparent 50%
  );
  background-image: linear-gradient(30deg, #2852db 50%, transparent 50%);
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: 300ms ease-in-out;
  transition: 300ms ease-in-out;
}

.types-list {
  list-style-type: number;
  width: 100%;
  text-decoration: none;
}

.list-link {
  text-decoration: none;
}

.list-link:hover {
  text-decoration: underline;
}

@media screen and (max-width :768px){

}

@media (min-width :768px) and (max-width:1024px) {
    .item-card-container{
      height: 310px !important;
    }  
    .item-card{
      
      display: flex;
      justify-content: flex-end;
      height: 100%;
    }
    .card-body{
      justify-content: flex-end !important;
      text-align: center;
    }
}



/* Skeleton Loader Styles */
.skeleton-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.skeleton-image {
  width: 300px;
  height: 300px; /* Adjust the height as needed */
  background-color: #f0f0f0;
  animation: skeleton 1s linear infinite alternate;
}

.skeleton-text {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  margin-bottom: 10px;
  animation: skeleton 1s linear infinite alternate;
}

/* Optional: Skeleton Loader Animation */
@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}
