.png-image{
    width: 90px;
    border-radius: 50% 50% 50% 50%;
    border-color: #DFEAFF;
    background-color: #6397ff;
    
    border-width: 8px 8px 8px 8px;
    border-style: solid;

}
.feature-card-title{
    font-family: var(--font);
    font-size: 2rem;
    text-align: center;
  
}
.txt-wrapper p{
    
    text-align: justify;
    font-size: 1.1rem !important;
}