.blogscardsContainer {
  display: flex;
  padding: 3% 10% 7% 10%;
  
}

.Blogs1Heading {
  padding-top: 1%;
  color: white;
  padding: 1.5% 3% 1% 3%;
  /* background: #001D47; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.466), rgba(0, 0, 0, 0.445)),
    url("https://firebasestorage.googleapis.com/v0/b/senwell-fasteners/o/BlogPage%2Fblog.png?alt=media&token=fb97e98d-b887-405a-9688-aec00285db11");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  height: 50vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.Blogs1Heading h1 {
  font-family: var(--font);
  font-size: var(--h1);
}

.blogsCard {
  width: 30% !important;
  min-height: 100% !important;

  margin: 0px 20px 0px 20px !important;
  /* transition: box-shadow 0.3s ease-out; */
  border-radius: 10px;
  padding: 1.7%;
  border: 1px solid rgb(184, 184, 184) !important;
}

.blogsCard img {
  border-radius: 10px;
}

/* .blogsCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
} */

.blogsCard .blogCard-title {
  margin-top: 15px;
  color: rgb(7, 34, 103);
  cursor: pointer;
}

.blogsCard .blogCard-title:hover {
  color: rgb(0, 194, 244);
}

.blogsCard p {
  margin-top: 15px;
  color: rgb(7, 34, 103);
}

.skeleton {
  animation: skeleton 1s linear infinite alternate;
  opacity: 1;
  height: auto;
  width: 100%;
}

.skeleton-text {
  width: 100%;
  height: 0.9rem;
  opacity: 1;
  border-radius: 10px;
  margin-bottom: 0.25rem;
  animation: skeleton 1s linear infinite alternate;
}

@keyframes skeleton {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@media screen and (max-width: 768px) {
  .Blogs1Body {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .Blogs1Heading{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blogscardsContainer {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2% 2% 10% 2%;
  }

  .blogsCard {
    margin: 20px 0px 20px 0px !important;
    width: 100% !important;
    height: auto !important;
  }

  .blogsCard .card-title {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blogscardsContainer {
    padding: 2% 2% 10% 2%;
  }
  .Blogs1Heading{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
