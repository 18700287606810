/* ProductCard.css */

.product-card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    padding: 0;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  
  .product-image {
    position: relative;
    width: 200px;
    height: 150px;
    overflow: hidden;
   
    /* transform: perspective(1000px) rotateX(20deg);
    transform-origin: bottom; */
  }
  
  .product-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  