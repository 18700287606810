

  p,li{
    font-family: var(--para) !important;
    /* font-weight: 500 !important; */
  }

  .font{
    font-family: var(--font);
    font-size: 3rem;
  }

/* Style the scrollbar track */
::-webkit-scrollbar {
    width: 10px;
}

/* Style the scrollbar thumb (the draggable handle) */
::-webkit-scrollbar-thumb {
    background-color: #8888885e; /* Change this to your desired thumb color */
    border-radius: 6px;
}

/* Style the scrollbar track (the area behind the thumb) */
::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Change this to your desired track color */
    border-radius: 1px;
}

/* Style the scrollbar corner */
::-webkit-scrollbar-corner {
    background-color: #f1f1f1; /* Change this to your desired color */
}
