@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:wght@600&family=Arimo:wght@600&family=Lato:wght@700&family=Poppins:wght@300&family=Raleway:ital,wght@0,300;0,400;1,300&family=Roboto:wght@400;500;700&display=swap");

:root {
    --main-font-size: 16px;  
    --h1:3rem;
    --main-bg-color: #ffffff ;
    --overview-bg-color : #c8b6ff!important;
    /* --font:'Alumni Sans', sans-serif; */
    --font:'Roboto', sans-serif;
    --para:'Roboto', sans-serif;
    --color-primary: #00337D; 
    /* --color-primary: #1A60A5;  */
    /* --color-secondary: #0171C1; */
    --color-secondary: #698d9e;
    /* font-family: Roboto, sans-serif; */
  }


  body {
    font-size: var(--main-font-size);  /* Use the font size variable */
    background-color: var(--main-bg-color); 
     
  }
html {
  scroll-behavior: smooth;
}