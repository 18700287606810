.swiper {
  width: 100%;
  /* min-height: 60vh; */
  /* height: 100%; */
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: white;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 61px;

  font-weight: 300;
  font-family: var(--font);
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.service h1 {
  font-family: var(--font);
  font-size: var(--h1);
}

@media screen and (max-width: 595px) {
  .video {
    height: 100vh;
    object-fit: cover;
  }
  .title{
    font-size: 6vh !important;
  }
}
