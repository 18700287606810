.blogHeading{
    
    padding: 1.5% 3% 1% 3%;
    height: 50vh;
    color: white;
   
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.555), rgba(0, 0, 0, 0.534)),
    url("../../../assets/BlogPage/1.webp"); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    
    height: 50vh;
   
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
   
  
}

.blogHeading h1{
    font-family: var(--font);
    font-size: var(--h1);
    width: 70%;
    
    
}

.blogHeading h1 a{
    color: white;
}

.blogsContent{
    padding: 5% 15% 5% 15%;
    /* padding: 3%; */
    background: white;
    border: 1px solid white;
    border-radius: 20px;
}

.movedLists{
    margin-left: -35px;
}


@media (min-width:768px) and (max-width:991px) {
    .blogHeading h1{
        padding-top: 5%;
        font-size: 40px !important;
    }
    
}
@media screen and (max-width:768px) {
    
   
    .blogHeading h1{
        font-size: 20px !important;
        text-align: center;
    }
    .blogBody2 .blogHeading,.blogBody3 .blogHeading{
        background-position: center;
        background-size: contain !important;
    }
}

